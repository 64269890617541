/*
 * @Author: ChenYaJin
 * @Date: 2023-09-07 10:01:23
 * @LastEditors: LiZhiWei 907574271@qq.com
 * @LastEditTime: 2024-10-09 09:59:54
 * @Description: 活动资讯
 */
import type { RouteLocationRaw } from '#vue-router'

/**资讯分类 */
export interface IInfoLevel {
  id?: string
  name?: string
  parentClassId?: string
  classLevel?: number
  sortValue?: number
  to?: string | RouteLocationRaw
  children?: IInfoLevel[]
}

/**资讯 */
export interface IInfo {
  id?: string
  /** 附件列表 */
  attachmentAddFormList?: {
    id: string
    name: string
  }[]
  name?: string
  pushStatus?: string
  backgroundImage?: string
  base64?: string
  content?: string
  type?: number // 类型 (0 图文；1 链接)
  jumpLink?: string // 跳转链接
  carousel?: number // 是否是轮播
  top?: number // 是否置顶
  pushTime?: string
  classId2?: string
}

/** 活动 */
export interface IActivity {
  id?: string
  name?: string
  type?: string
  startAt?: string
  entAt?: string
  status?: string
  participateNum?: number
  reviewerByNum?: number
  infoId?: string
  enterpriseActivityParticipant?: number
}

export class Activity implements IActivity {
  id?: string
  name?: string
  type?: string
  startAt?: string
  entAt?: string
  status?: string
  participateNum?: number
  reviewerByNum?: number
  infoId?: string
  enterpriseActivityParticipant?: number
  constructor(json: IActivity = {}) {
    this.id = json.id
    this.name = json.name
    this.type = json.type
    this.startAt = json.startAt
    this.entAt = json.entAt
    this.status = json.status
    this.participateNum = json.participateNum
    this.reviewerByNum = json.reviewerByNum
    this.infoId = json.infoId
    this.enterpriseActivityParticipant = json.enterpriseActivityParticipant
  }
}

export interface IAttachmentList {
  businessLicense?: fileType //营业执照
  legalObtainList?: fileType[] //项目依法取得的有关资料列表
  propertyContractList?: fileType[] //项目物业服务合同列表
  overviewList?: fileType[] //项目概况介绍列表
  occupancyRateList?: fileType[] //项目收房率情况说明列表
  engineeringList?: fileType[] //工程项目相关资料列表
  excellenceList?: fileType[] //项目创优实施方案和汇报材料列表
  commitment?: fileType //承诺函
  municipalExcellenceDeclare?: fileType //市优项目申报备案表
}

/** 市优活动 */
export interface IExcellenceActivity {
  id?: string
  enterpriseName?: string //企业名称
  projectName?: string //项目名称
  projectAddress?: string //项目地址
  isResidence?: boolean //项目类型 - 居住物业 - 住宅小区
  isVilla?: boolean //项目类型 - 居住物业 - 别墅
  isOffice?: boolean //项目类型 - 非居住物业 - 写字楼
  isBusiness?: boolean //项目类型 - 非居住物业 - 商场
  isSchool?: boolean //项目类型 - 非居住物业 - 学校
  isHospital?: boolean //项目类型 - 非居住物业 - 医院
  isVenue?: boolean //项目类型 - 非居住物业 - 场馆
  isIndustrial?: boolean //项目类型 - 非居住物业 - 工业园区
  isOther?: boolean //项目类型 - 非居住物业 - 其他
  otherName?: string //项目类型 - 非居住物业 - 其他名称
  projectArea?: string //建筑面积 万平方米
  enterprisePrincipal?: string //企业负责人
  enterprisePrincipalPhone?: string //企业负责人电话
  projectPrincipal?: string //项目负责人
  projectPrincipalPhone?: string //项目负责人电话
  residenceServiceCharge?: string //服务费标准 住宅
  notResidenceServiceCharge?: string //服务费标准 非住宅
  declareRecount?: string //申报自述
  recommend?: string //各区物业管理行政部门推荐
  // auditOpinionScore?: string //评审小组审核意见 评分 *非参与方填写
  // auditOpinion?: string //评审小组审核意见 评语 *非参与方填写
  attachmentList?: IAttachmentList
  enterpriseId?: string
  communityId?: string
}

export type fileType = {
  id: string
  name: string
}

export class AttachmentList implements IAttachmentList {
  businessLicense?: fileType
  legalObtainList?: fileType[]
  propertyContractList?: fileType[]
  overviewList?: fileType[]
  occupancyRateList?: fileType[]
  engineeringList?: fileType[]
  excellenceList?: fileType[]
  commitment?: fileType
  municipalExcellenceDeclare?: fileType

  constructor(json: IAttachmentList = {}) {
    this.businessLicense = json.businessLicense
    this.legalObtainList = json.legalObtainList || []
    this.propertyContractList = json.propertyContractList || []
    this.overviewList = json.overviewList || []
    this.occupancyRateList = json.occupancyRateList || []
    this.engineeringList = json.engineeringList || []
    this.excellenceList = json.excellenceList || []
    this.commitment = json.commitment
    this.municipalExcellenceDeclare = json.municipalExcellenceDeclare
  }
}

export class ExcellenceActivity implements IExcellenceActivity {
  id?: string
  enterpriseName?: string
  projectName?: string
  projectAddress?: string
  isResidence?: boolean
  isVilla?: boolean
  isOffice?: boolean
  isBusiness?: boolean
  isSchool?: boolean
  isHospital?: boolean
  isVenue?: boolean
  isIndustrial?: boolean
  isOther?: boolean
  otherName?: string
  projectArea?: string
  enterprisePrincipal?: string
  enterprisePrincipalPhone?: string
  projectPrincipal?: string
  projectPrincipalPhone?: string
  residenceServiceCharge?: string
  notResidenceServiceCharge?: string
  declareRecount?: string
  // recommend?: string
  // auditOpinionScore?: string
  // auditOpinion?: string
  attachmentList?: AttachmentList
  enterpriseId?: string
  communityId?: string
  constructor(json: IExcellenceActivity = {}) {
    this.id = json.id || ''
    this.enterpriseName = json.enterpriseName || ''
    this.projectName = json.projectName || ''
    this.projectAddress = json.projectAddress || ''
    this.isResidence = json.isResidence || false
    this.isVilla = json.isVilla || false
    this.isOffice = json.isOffice || false
    this.isBusiness = json.isBusiness || false
    this.isSchool = json.isSchool || false
    this.isHospital = json.isHospital || false
    this.isVenue = json.isVenue || false
    this.isIndustrial = json.isIndustrial || false
    this.isOther = json.isOther || false
    this.otherName = json.otherName || ''
    this.projectArea = json.projectArea || ''
    this.enterprisePrincipal = json.enterprisePrincipal || ''
    this.enterprisePrincipalPhone = json.enterprisePrincipalPhone || ''
    this.projectPrincipal = json.projectPrincipal || ''
    this.projectPrincipalPhone = json.projectPrincipalPhone || ''
    this.residenceServiceCharge = json.residenceServiceCharge || ''
    this.notResidenceServiceCharge = json.notResidenceServiceCharge || ''
    this.declareRecount = json.declareRecount || ''
    // this.recommend = json.recommend || ''
    // this.auditOpinionScore = json.auditOpinionScore
    // this.auditOpinion = json.auditOpinion
    this.attachmentList = new AttachmentList(json.attachmentList || {})
    this.communityId = json.communityId || ''
    this.enterpriseId = json.enterpriseId
  }
}

/** 活动评分 */
export interface IActivityScore {
  id?: string
  activityId?: string
  activityName?: string
  averageScore?: number
  status?: string
  commentId?: string
  scoringDecideName?: string
  isScoringScore?: boolean
  isScoringDecide?: boolean
}

/** 活动评分详情 */
export interface IScoreDetail {
  rated?: number
  maxTotal?: number
  classs?: ScoreClass[]
}

/** 活动评语详情 */
export interface ICommentDetail {
  comment: string
}

export class ScoreDetail implements IScoreDetail {
  rated?: number
  maxTotal?: number
  classs?: ScoreClass[]

  constructor(json: IScoreDetail = {}) {
    this.rated = json.rated || 0
    this.maxTotal = json.maxTotal || 0
    this.classs = json.classs?.map((item) => new ScoreClass(item))
  }

  getScoreTotal() {
    const scores = this.classs?.map((item) => item.getScoreTotal3())
    const total = scores?.reduce((a, b) => {
      return Number(a) + Number(b)
    }, 0)
    return (total || 0).toFixed(2)
  }
}

/** 评分-分类 */
export interface IScoreClass {
  id?: string
  name?: string
  maxTotal?: number
  standardList?: IScoreStandard[]
}

export class ScoreClass implements IScoreClass {
  id?: string
  name?: string
  maxTotal?: number
  standardList?: IScoreStandard[]

  constructor(json: IScoreClass = {}) {
    this.id = json.id
    this.name = json.name
    this.maxTotal = json.maxTotal
    this.standardList = json.standardList || []
  }

  getScoreTotal() {
    const scores = this.standardList?.map((item) => item.score)
    const total = scores?.reduce((a, b) => {
      return Number(a) + Number(b)
    }, 0)
    return (total || 0).toFixed(2)
  }
  getScoreTotal3() {
    const scores = this.standardList?.map((item) => item.score)
    const total = scores?.reduce((a, b) => {
      return Number(a) + Number(b)
    }, 0)
    return (total || 0).toFixed(3)
  }
}

/** 评分标准 */
export interface IScoreStandard {
  id?: string
  name?: string
  content?: string
  maximumScore?: number
  must?: boolean
  comment?: string
}

// export class ScoreStandard implements IScoreStandard {
//   id?: string
//   name?: string
//   content?: string
//   maximumScore?: number
//   must?: boolean
//   score?: number

//   constructor(json: IScoreStandard = {}) {
//     this.id = json.id
//     this.name = json.name
//     this.content = json.content
//     this.maximumScore = json.maximumScore
//     this.must = json.must
//     this.score = json.score
//   }
// }

export enum DetailType {
  info = 'info',
  activity = 'activity',
}
